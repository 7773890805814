import CustomImage from "@/components/CustomImage";
import {
  companyFooter,
  businessFooter,
  exchangeFooter,
  resourceFooter,
} from "@/utils/dummy";
import React from "react";
import { FaFacebookF } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn, FaTelegram, FaYoutube } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useIpAddressAPI, useLocationAPI } from "@/api/products";
import { RootState } from "@/store";
import { useCategoriesAPI } from "@/api/categories";
import Link from "next/link";
import { Tooltip } from "react-tooltip";

const Footer = () => {
  const location = useSelector((state: RootState) => state.location);
  const { data: ipAddress } = useIpAddressAPI();
  const { data: locationData } = useLocationAPI(ipAddress);
  const { data: categoryData, isLoading: loadingCategory }: any =
    useCategoriesAPI(location?.country);
  const categories = categoryData?.data;
  return (
    <footer>
      <div className="footerList container">
        <div className="logoFooter">
          <CustomImage src={"indexPage/navLogo.svg"} className="footerLogo" />

          {/* <p>
            Lorem Ipsum is simply dummy text of printing and typesetting
            industry Lorem Ipsum is simply dummy text of printing and
            typesetting industry.
          </p> */}
          <p
            className="appStores"
            style={{
              cursor: "not-allowed",
            }}
          >
            <CustomImage src={"indexPage/appStore.svg"} className="zoom" />
            <CustomImage src={"indexPage/googleStore.svg"} className="zoom" />
          </p>
          <span
            style={{
              color: "white",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            Coming Soon .....
          </span>
        </div>
        <ul>
          <h6>Help Center</h6>
          <li>
            <span>
              <Link href={"/support"}>Support</Link>
            </span>
          </li>
          <li>
            <span>
              <Link href={"/faq"}>FAQ</Link>
            </span>
          </li>
        </ul>
        <ul>
          <h6>Products</h6>
          {categories
            // ?.slice(0, 3)
            ?.filter((item: any) => item.count !== 0)
            ?.map((item: any, key: number) => (
              <li key={key}>
                <Link href={`/categories/${item?.id}`} aria-current="page">
                  {item?.category}
                </Link>
              </li>
            ))}
        </ul>
        <ul>
          <h6>Follow Us</h6>
          <li>
            <Link
              href={"https://www.facebook.com/Switchive/?_rdc=1&_rdr"}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <FaFacebookF />
              <span>Facebook</span>
            </Link>
          </li>
          <li>
            <Link
              href={"https://www.instagram.com/switchive/"}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <RiInstagramFill />
              <span>Instagram</span>
            </Link>
          </li>
          <li>
            <Link
              href={"https://twitter.com/switchive"}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <FaXTwitter />
              <span>X</span>
            </Link>
          </li>
          <li>
            <Link
              href={"https://www.linkedin.com/company/switchive/"}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <FaLinkedinIn />
              <span>Linkedin</span>
            </Link>
          </li>
          <li>
            <Link
              href={"https://t.me/switchiveofficial"}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <FaTelegram />
              <span>Telegram</span>
            </Link>
          </li>
        </ul>
      </div>

      <div className="container pb-3 mt-0 pt-0 mx-auto">
        <hr />
        <div className="copyRight p-2">
          <span className="">
            Copyright {new Date().getFullYear()}, Switchive
          </span>

          <ul
            className="footerList mt-3"
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
            }}
          >
            <li>
              <Link href={"/terms-of-service"}>Terms of Service</Link>
            </li>
            <li>
              <Link href={"/privacy-policy"}>Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
